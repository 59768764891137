import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PanAppState, USER_ACTIONS, UserState } from '@panamax/app-state';
import { User } from '@usf/user-types/user';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  first,
  map,
  tap,
} from 'rxjs/operators';
import { UserDataService } from '../../services/user-data.service';
import { UserActions } from '../actions/action-types';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  getUserbyToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserByToken),
      exhaustMap(action =>
        combineLatest([
          this.userDataService.getByToken(),
          this.userDataService.getUserProfiles(),
        ]).pipe(
          first(
            ([userPayload, profilesPayload]) =>
              !!userPayload && !!profilesPayload,
          ),
          concatMap(([userPayload, profilesPayload]) => [
            UserActions.getUserByTokenSuccess({
              user: userPayload,
              profiles: profilesPayload,
            }),
            UserActions.getUserPreferences(),
            UserActions.getUserCustomization(),
            USER_ACTIONS.loadUserSuccess({
              loggedInUser: this.createPanamaxUser(
                userPayload,
                action?.impersonatorID,
              ),
            }),
            USER_ACTIONS.loadProfileSettings({
              profileSettings: profilesPayload,
            }),
          ]),
          catchError(err => of(UserActions.getUserByTokenFail({ error: err }))),
        ),
      ),
    ),
  );

  getUserCustomizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserCustomization),
      concatMap(action => {
        return this.userDataService.getUserCustomizations().pipe(
          map(response =>
            UserActions.getUserCustomizationSuccess({
              customization: response[0],
            }),
          ),
          tap(response => {
            console.log('CUSTOMIZATIONS', response);
            this.panAppState.setCustomization(
              response?.customization?.profileName ?? null,
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.panAppState.setCustomization(null);
            return of(UserActions.getUserCustomizationFail({ error }));
          }),
        );
      }),
    ),
  );

  getUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserPreferences),
      concatMap(action => {
        return this.userDataService.getUserPreferences().pipe(
          map(response =>
            UserActions.getUserPreferencesSuccess({ preferences: response }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.getUserPreferencesFail({ error })),
          ),
        );
      }),
    ),
  );

  updateUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserPreferences),
      concatMap(action => {
        return this.userDataService
          .updateUserPreferences(action.newPreferences)
          .pipe(
            map(response => UserActions.updateUserPreferencesSuccess()),
            catchError((error: HttpErrorResponse) =>
              of(
                UserActions.updateUserPreferencesFail({
                  error,
                  oldPreferences: action.oldPreferences,
                }),
              ),
            ),
          );
      }),
    ),
  );

  getEmailPhoneByUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getEmailPhoneByUsername),
      concatMap(action => {
        return this.userDataService
          .getEmailPhoneByUsername(action.username)
          .pipe(
            map(response =>
              UserActions.getEmailPhoneByUsernameSuccess({
                emailAndPhone: response,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(UserActions.getEmailPhoneByUsernameFail({ error })),
            ),
          );
      }),
    ),
  );

  getInteractBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getInteractBanners),
      concatMap(action => {
        return this.userDataService.getInteractBanners().pipe(
          map(response =>
            UserActions.getInteractBannersSuccess({
              interactBanners: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.getInteractBannersFail({ error })),
          ),
        );
      }),
    ),
  );

  getCustomMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomMessage),
      concatMap(action => {
        return this.userDataService.getCustomMessage().pipe(
          map(response =>
            UserActions.getCustomMessageSuccess({
              customMessage: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.getCustomMessageFail({ error })),
          ),
        );
      }),
    ),
  );

  getCustomMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomMessages),
      concatMap(action => {
        return this.userDataService.getCustomMessages().pipe(
          map(response =>
            UserActions.getCustomMessagesSuccess({
              customMessages: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.getCustomMessagesFail({ error })),
          ),
        );
      }),
    ),
  );

  saveCustomMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveCustomMessage),
      concatMap(action => {
        return this.userDataService
          .saveCustomMessage(action.customMessage)
          .pipe(
            map(response =>
              UserActions.saveCustomMessageSuccess({
                customMessage: action.customMessage,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(UserActions.saveCustomMessageFail({ error })),
            ),
          );
      }),
    ),
  );

  deleteCustomMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteCustomMessage),
      concatMap(action => {
        return this.userDataService.deleteCustomMessage(action.messageIds).pipe(
          map(response =>
            UserActions.deleteCustomMessageSuccess({
              messageIds: action.messageIds,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.deleteCustomMessageFail({ error })),
          ),
        );
      }),
    ),
  );

  inviteNewUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.inviteNewUser),
      concatMap(action => {
        return this.userDataService
          .inviteNewUser(action.inviteEmail, action.customers)
          .pipe(
            map(response =>
              UserActions.inviteNewUserSuccess({
                inviteEmail: action.inviteEmail,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                UserActions.inviteNewUserFail({
                  error,
                  inviteEmail: action.inviteEmail,
                }),
              ),
            ),
          );
      }),
    ),
  );

  searchUserByUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.searchUserByUsername),
      concatMap(action => {
        return this.userDataService.searchUserByUsername(action.userName).pipe(
          map((response: User) =>
            UserActions.searchUserByUsernameSuccess({
              user: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              UserActions.searchUserByUsernameFail({
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private userDataService: UserDataService,
    private panAppState: PanAppState,
  ) {}

  createPanamaxUser(user: User, impersonation?: string): UserState {
    const statObj = {
      organizationName: user.organizationName,
      userId: user.userName.replace('[AD_AUTO]', ''),
      ecomUserId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userStatus: user.userStatus,
      lastLoginDtm: user.lastLoginDtm,
      userType: user.userType,
      partnerId: user.partnerId,
      userKind: user.userKind,
      impersonatorID: '',
      addDtm: user.addDtm,
    };
    !!impersonation
      ? (statObj['impersonatorID'] = impersonation)
      : (statObj['impersonatorID'] = '');
    return statObj;
  }
}
