import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  PanAppState,
  PlatformService,
  SelectedCustomerState,
} from '@panamax/app-state';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { Observable, Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-invite-new-user-modal',
  templateUrl: './invite-new-user-modal.component.html',
  styleUrls: ['./invite-new-user-modal.component.scss'],
})
export class InviteNewUserModalComponent implements OnInit {
  newUserEmailAddress: string;
  inviteNewUserForm: UntypedFormGroup;
  inviteUserForm: UntypedFormGroup;
  customer$: Subscription;
  customer: SelectedCustomerState;
  inviteUserFlag$: Observable<boolean>;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private fb: UntypedFormBuilder,
    private inviteNewUserService: InviteNewUserService,
    private pannAppState: PanAppState,
  ) {}

  ngOnInit(): void {
    this.inviteUserFlag$ = this.inviteNewUserService.inviteUserFlag$();
    this.inviteNewUserService.setupInviteUserSubscriptions();
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        Validators.compose([
          Validators.maxLength(80),
          Validators.required,
          Validators.email,
        ]),
      ],
    });
    this.inviteUserForm = this.fb.group({
      inviteUserType: ['', Validators.required],
    });
    this.customer$ = this.pannAppState.customer$
      .pipe(filter(cust => !!cust))
      .subscribe(cust => {
        this.customer = cust;
      });
  }

  async dismiss() {
    this.inviteNewUserForm.reset();
    await this.modalController.dismiss(true);
  }

  inviteNewUser() {
    if (this.inviteNewUserForm?.valid && this.customer) {
      this.inviteNewUserService.inviteNewUser(
        this.inviteNewUserForm.controls['newUserEmailAddress'].value,
        [
          {
            customerNumber: this.customer?.customerNumber,
            divisionNumber: this.customer?.divisionNumber,
          },
        ],
      );
      this.dismiss();
    }
  }
}