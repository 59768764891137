import {
  GroupRow,
  ProductRow,
} from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { desiredColumn } from '@shared/models/desired-column';
import { Customer } from '@usf/customer-types';
import jsPDF from 'jspdf';
import autoTable, { CellDef, CellHookData } from 'jspdf-autotable';
import { PDFColors } from '../../../constants/pdf-colors.enum';
import { ImageIconPair, ListColumns } from './document-helper-models-and-enums';
import {
  getColumnStyles,
  loadImage,
  loadImagePair,
} from './general-pdf-helper';
import {
  BODY_FONT,
  FOOTER_FONT,
  HEADER_FONT,
  LARGE_HEADER_FONT,
  LOGO_FONT,
  SUB_HEADER_FONT,
} from '@shared/constants/pdf-font';
import { getRemotePillAssets } from '@shared/helpers/document-creation.helpers';
import { ServiceHandlerService } from '@shared/services/service-handler.service';

const isRemoteAsset = (path: string): boolean => {
  const externalPattern = /^(https?:\/\/|www\.)/i;
  return externalPattern.test(path);
};

export const pdfToBlob = async (
  listItemTableColumns: CellDef[][],
  listDataTable: string[][][],
  groupNamesInOrder: string[],
  styleColumnReference: desiredColumn[],
  fileName: string,
  imageIndexStatusType: number[],
  customer: Customer,
  productsCount: number,
  divisionName: string,
  listName: string,
  departmentNumber: number,
  imageKeys: Set<string>,
  isMobile: boolean,
  serviceHandler: ServiceHandlerService,
): Promise<Blob> => {
  // l == landscape
  const doc = new jsPDF('l', 'px', [1440, 1055]);
  let imagePromises: Promise<ImageIconPair>[] = [];
  const imageMap = new Map<string, HTMLImageElement>();
  if (imageIndexStatusType.length > 0) {
    const remoteImageMap = await getRemoteImages(serviceHandler, imageKeys);
    for (const imageSrc of imageKeys) {
      if (isRemoteAsset(imageSrc)) {
        const image = remoteImageMap.get(imageSrc);
        if (!!image) {
          let pair = new Promise<ImageIconPair>((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ icon: imageSrc, image: img });
            img.onerror = reject;
            img.src = image;
          });
          imagePromises.push(pair);
        }
      } else {
        imagePromises.push(loadImagePair(imageSrc));
      }
    }
  }
  const imageArray = await Promise.all(imagePromises);
  imageArray.forEach(imageIconPair => {
    imageMap.set(imageIconPair.icon, imageIconPair.image);
  });

  doc.setProperties({});
  doc.setProperties({ title: fileName });
  let trimmedName = '';
  if (listName.length > 30) {
    trimmedName = listName.substring(0, 30);
  } else {
    trimmedName = listName;
  }
  let department;
  if (departmentNumber)
    department = customer?.departments?.find(
      dept => dept.departmentNumber === departmentNumber.toString(),
    );

  const footerHeight = 50;

  await createHeader(
    doc,
    trimmedName,
    productsCount,
    divisionName,
    customer.divisionNumber,
    customer.customerName,
    customer.customerNumber,
    department?.departmentName ?? '',
    department?.departmentNumber ?? '',
    isMobile,
  );
  let groupNameIndex = 0;
  let nextTableYPosition = isMobile ? 135 : 145;
  listDataTable.forEach(itemTablePerGroup => {
    autoTable(doc, {
      body: [
        [
          {
            content: `${groupNamesInOrder[groupNameIndex]} (${itemTablePerGroup.length} products)`,
            styles: {
              fillColor: PDFColors.pdfWhite,
              halign: 'left',
              valign: 'middle',
              fontSize: BODY_FONT,
              textColor: PDFColors.pdfBlack,
              lineColor: PDFColors.pdfBlack,
              lineWidth: 1,
              minCellHeight: 34,
              font: 'helvetica',
              fontStyle: 'bold',
            },
          },
        ],
      ],
      startY: nextTableYPosition,
      margin: {
        left: 10,
        right: 10,
        bottom: footerHeight + 10,
      },
      theme: 'grid',
      tableWidth: 'auto',
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      styles: {
        lineColor: PDFColors.pdfBlack,
        lineWidth: 1,
      },
    });

    nextTableYPosition = (doc as any).previousAutoTable.finalY;
    const columnStyles = getColumnStyles(styleColumnReference);
    autoTable(doc, {
      head: listItemTableColumns,
      body: itemTablePerGroup,
      startY: nextTableYPosition,
      margin: {
        left: 10,
        right: 10,
        bottom: footerHeight + 10,
      },
      theme: 'grid',
      tableWidth: 'auto',
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      styles: {
        fontSize: BODY_FONT,
        halign: 'center',
        valign: 'middle',
        lineColor: PDFColors.pdfBlack,
        lineWidth: 1,
        minCellHeight: 40,
      },
      columnStyles,
      willDrawCell: (cellData: CellHookData) => {
        if (
          cellData.row.section == 'body' &&
          imageIndexStatusType.includes(cellData.column.index)
        ) {
          doc.setTextColor(PDFColors.pdfWhite);
        } else if (cellData.row.section == 'body') {
          doc.setTextColor(PDFColors.pdfBlack);
        }
      },
      didDrawCell: (cellData: CellHookData) => {
        if (
          cellData.row.section == 'body' &&
          imageIndexStatusType.includes(cellData.column.index)
        ) {
          let imageIcon = '';
          cellData.cell.text.forEach(text => (imageIcon += text));
          const imageFound = imageIcon !== '';
          if (imageFound) {
            try {
              doc.addImage(
                imageMap.get(imageIcon),
                'PNG',
                cellData.cell.x + 7.5,
                cellData.cell.y + (cellData.cell.height - 20) / 2,
                90,
                20,
              );
            } catch (ignore) {}
          }
        }
      },
    });

    nextTableYPosition = (doc as any).previousAutoTable.finalY;
    groupNameIndex++;
  });

  createFooter(doc, footerHeight);
  return doc.output('blob');
};

export const createHeader = async (
  doc: jsPDF,
  listName: string,
  productCount: number,
  divisionName: string,
  division: number,
  customerName: string,
  customerNumber: number,
  department: string,
  departmentNumber: string,
  isMobile: boolean,
) => {
  const pageSize = doc.internal.pageSize;

  if (!isMobile) {
    const image = await loadImage('../assets/images/usfoods-logo-print.jpg');
    doc.addImage(image, 35, 25, 82, 80);
  } else {
    doc.setFontSize(LOGO_FONT);
    doc.setFont('helvetica', 'bold');
    doc.text('US', 65, 55);
    doc.text('Foods', 50, 80);
  }

  doc.setFontSize(LARGE_HEADER_FONT);
  doc.setFont('helvetica', 'bold');
  doc.text(listName, 140, 50);
  doc.setFontSize(HEADER_FONT);
  const numLower = listName.replace(/[^a-z]/g, '').length;
  const numUpperAndSpecial = listName.length - numLower;
  const lenOfListName = 23 * numUpperAndSpecial + 18 * numLower;
  doc.text(`${productCount} products`, 140 + lenOfListName, 50);

  doc.setFontSize(SUB_HEADER_FONT);
  doc.setFont('helvetica', 'normal');
  const createdDate = new Date();
  doc.text(
    `Created: ${createdDate
      .toLocaleDateString('en-US')
      .toString()} at ${createdDate.toLocaleTimeString('en-US')}`,
    140,
    isMobile ? 75 : 85,
  );

  const lastLineHeight = isMobile ? 95 : 105;
  let runningXPosition = 140;
  doc.setFont('helvetica', 'bold');
  doc.text('Division: ', runningXPosition, lastLineHeight);
  runningXPosition += 'Division: '.length * 7;
  doc.setFont('helvetica', 'normal');
  doc.text(`${divisionName} (${division})`, runningXPosition, lastLineHeight);
  runningXPosition += `${divisionName} (${division})`.length * 7 + 40;
  doc.setFont('helvetica', 'bold');
  doc.text('Customer: ', runningXPosition, lastLineHeight);
  runningXPosition += `Customer: `.length * 7;
  doc.setFont('helvetica', 'normal');
  doc.text(
    `${customerName} (${customerNumber})`,
    runningXPosition,
    lastLineHeight,
  );
  runningXPosition += `${customerName} (${customerNumber})`.length * 7 + 40;

  if (department !== '') {
    doc.setFont('helvetica', 'bold');
    doc.text('Department: ', runningXPosition, lastLineHeight);
    runningXPosition += 'Department: '.length * 7;
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${department}(${departmentNumber})`,
      runningXPosition,
      lastLineHeight,
    );
  }

  doc.setDrawColor(PDFColors.pdfGrey);
  doc.line(0, lastLineHeight + 25, pageSize.getWidth(), lastLineHeight + 25);

  return doc;
};

export const createFooter = (doc: jsPDF, footerHeight: number) => {
  const pageCount: number = (doc as any).internal.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.line(
      0,
      pageHeight - footerHeight,
      pageSize.getWidth(),
      pageHeight - footerHeight,
    );

    doc.setFontSize(FOOTER_FONT);
    doc.text(
      'For the exclusive use by US Foods customers',
      20,
      pageHeight - footerHeight / 2,
    );

    const printDate = new Date();
    doc.text(
      `Print Date: ${printDate
        .toLocaleDateString('en-US')
        .toString()}  |  Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 200,
      pageHeight - footerHeight / 2,
    );
  }
  return doc;
};

export const collectGroupRows = (items: GroupRow[]): string[] => {
  return items.map(group => group.groupName);
};

export const collectGroupNames = (items: ProductRow[]): string[] => {
  return items.map(product => product?.groupName ?? '');
};

export const getPDFColumns = (
  includePoductPrices: boolean,
  includePoductNotes: boolean,
  includeProductType: boolean,
  includeProductStatus: boolean,
  isMobile: boolean,
  isRecentPurchase: boolean,
): desiredColumn[] => {
  let productDescriptionWidth = 240;
  let productBrandWidth = 100;
  let productPackWidth = 100;
  let productNumberWidth = 65;
  let productPriceWidth = 100;

  if (!includePoductNotes) {
    productDescriptionWidth += 160;
    if (!includeProductStatus) {
      productBrandWidth += 50;
      productPackWidth += 50;
    }
    if (!includeProductType) {
      productBrandWidth += 50;
      productPackWidth += 50;
    }

    if (!includePoductPrices) {
      productBrandWidth += 50;
      productPackWidth += 50;
    }
  } else {
    if (!includeProductStatus) {
      productDescriptionWidth += 105;
    }
    if (!includeProductType) {
      productDescriptionWidth += 105;
    }

    if (!includePoductPrices) {
      productBrandWidth += 50;
      productPackWidth += 50;
    }
  }

  if (isRecentPurchase) {
    productBrandWidth += 50;
    productPackWidth += 50;
    productNumberWidth += 75;
    productPriceWidth += 50;
  }

  const seperatedColumns: desiredColumn[] = [];
  if (!isRecentPurchase)
    seperatedColumns.push({
      columnName: 'Line #',
      columnType: ListColumns.lineNumber,
      cellWidth: 50,
    });

  seperatedColumns.push({
    columnName: 'Product #',
    columnType: ListColumns.productNumber,
    cellWidth: productNumberWidth,
  });

  if (isRecentPurchase) {
    seperatedColumns.push({
      columnName: 'Product Description',
      columnType: ListColumns.productDescription,
      halign: 'left',
      cellPadding: 10,
    });
  } else {
    seperatedColumns.push({
      columnName: 'Product Description',
      columnType: ListColumns.productDescription,
      cellWidth: productDescriptionWidth,
      halign: 'left',
      cellPadding: 10,
    });
  }

  if (includePoductNotes)
    seperatedColumns.push({
      columnName: 'Product Note',
      columnType: ListColumns.productNote,
      cellWidth: 160,
      halign: 'left',
      cellPadding: 5,
    });

  if (includeProductStatus) {
    if (isMobile) {
      seperatedColumns.push({
        columnName: 'Product Status',
        columnType: ListColumns.productStatus,
        cellWidth: 125,
      });
    } else {
      seperatedColumns.push({
        columnName: 'Product Status',
        columnType: ListColumns.imageStatus,
        cellWidth: 105,
      });
    }
  }

  if (includeProductType) {
    if (isMobile) {
      seperatedColumns.push({
        columnName: 'Product Type',
        columnType: ListColumns.productType,
        cellWidth: 125,
      });
    } else {
      seperatedColumns.push({
        columnName: 'Product Type',
        columnType: ListColumns.imageType,
        cellWidth: 105,
      });
    }
  }

  seperatedColumns.push({
    columnName: 'Brand',
    columnType: ListColumns.productBrand,
    cellWidth: productBrandWidth,
    cellPadding: 5,
  });
  if (!isRecentPurchase)
    seperatedColumns.push({
      columnName: 'On Hand / Order',
      columnType: ListColumns.onHand,
      colSpan: 7,
    });
  seperatedColumns.push({
    columnName: 'Pack Size',
    columnType: ListColumns.productPackageSize,
    cellWidth: productPackWidth,
  });

  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Case / Each Price',
      columnType: ListColumns.eachAndCasePrice,
      cellWidth: productPriceWidth,
    });

  return seperatedColumns;
};

const getRemoteImages = async (
  serviceHandler: ServiceHandlerService,
  imageKeys: Set<string>,
) => {
  const remoteImageMap = new Map<string, string>();

  const remoteAssetsUrls = [...imageKeys].filter(image => isRemoteAsset(image));

  if (remoteAssetsUrls.length > 0) {
    try {
      const remoteAssetImages = await getRemotePillAssets(
        serviceHandler,
        remoteAssetsUrls,
      );

      if (remoteAssetsUrls.length === remoteAssetImages.length) {
        remoteAssetsUrls.forEach((url, index) => {
          remoteImageMap.set(url, remoteAssetImages[index].pngImageBase64);
        });
      } else {
        console.error(
          'Failed to get remote pill assets: Mismatch between URLs and returned images.',
        );
      }
    } catch (error) {
      console.error('Failed to get remote pill assets:', error);
    }
  }

  return remoteImageMap;
};
