export enum MultiSelectMode {
  IonicDefault,
  Unlimited,
  Limited,
}

export const areDatesEqual = (dateOne: Date, dateTwo: Date) => {
  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
};

export const isDateGreater = (dateOne: Date, dateTwo: Date) => {
  if (dateOne.getFullYear() > dateTwo.getFullYear()) {
    return true;
  }
  if (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() > dateTwo.getMonth()
  ) {
    return true;
  }

  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() > dateTwo.getDate()
  );
};

export const convertStringToDate = (utcString: string) => {
  const splitter = utcString.includes('-') ? '-' : '/';
  const dateToReturn = new Date();
  let splitVal: string[] = [];
  if (utcString.includes('T')) {
    splitVal = utcString.split('T')[0].split(splitter);
  } else {
    splitVal = utcString.split(splitter);
  }
  dateToReturn.setFullYear(Number(splitVal[0]).valueOf());
  dateToReturn.setDate(Number(splitVal[2]).valueOf());
  // Month comes in as 1...12 format, but SetMonth takes 0...11
  const month = Number(splitVal[1].valueOf()) - 1;
  dateToReturn.setMonth(month);

  return dateToReturn;
};

export const datesMatch = (dates: string[], check: string[]) => {
  let doDatesMatch = true;
  if (dates === undefined || dates.length != check.length) {
    return false;
  }
  for (let i = 0; i < dates.length; i++) {
    if (dates[i] !== check[i]) {
      doDatesMatch = false;
    }
  }
  return doDatesMatch;
};

export const formatDateToMMDDYYYY = (date: Date) => {
  let dateFormatted = '';
  if (date.getMonth() + 1 < 10) {
    dateFormatted += '0' + (date.getMonth() + 1).toString();
  } else {
    dateFormatted += (date.getMonth() + 1).toString();
  }

  if (date.getDate() < 10) {
    dateFormatted += '0' + date.getDate().toString();
  } else {
    dateFormatted += date.getDate().toString();
  }

  dateFormatted += date.getFullYear().toString();

  return dateFormatted;
};
export const formatDateToMonthSlashDaySlashYear = (date: Date) => {
  return (
    (date.getMonth() + 1).toString() +
    '/' +
    date.getDate().toString() +
    '/' +
    date.getFullYear().toString()
  );
};

export const grabDateFromStringDateType = (value: Date | string): Date => {
  if (typeof value === 'string') {
    return convertStringToDate(value);
  } else {
    return value;
  }
};

export const defaultIsDateEnabled = () => {
  return true
};