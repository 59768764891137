import { createSelector } from '@ngrx/store';
import {
  SelectedCustomerState,
  UserState,
  selectAllFeaturesEnabled,
} from '@panamax/app-state';
import {
  Order,
  OrderStatus,
  orderSelectors,
  partnerSelectors,
} from '@usf/ngrx-order';
import { OrderState } from '@usf/ngrx-order';
import { DeliveryDetailsState } from '@usf/ngrx-order';
import { OrderTrackingItem } from '@usf/order-types';
import {
  getCustomers,
  selectAppStateSelectedCustomer,
  selectAppStateUser,
} from 'src/app/ngrx-customer/store';
import { OrderStatusDetails } from 'src/app/shared/constants/order-status-title.enum';
import { Product } from 'src/app/shared/models/product.model';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import {
  OrderTrackingGroup,
  SubbedItem,
  SubmittedOrderTallies,
  SubmittedOrderViewModel,
} from '../models/submitted-order-view-model';
import { OrderHeaderService } from '../services/order-header.service';
import { FEATURES } from 'src/app/shared/constants/splitio-features';
import {
  selectUserCustomizations,
  selectUserProfiles,
} from '@app/user/store/selectors/user.selectors';
import {
  AccessRoleKeyEnum,
  AccessRoleValueEnum,
} from '@shared/constants/order-info-access-role.constant';
import { UserProfile } from '@usf/user-types/user-profile';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { PUNCHOUT_PO_PENDING } from '@shared/constants/order-punchout';
import { selectOrderStatusDisplayEnabled } from './my-orders-view-model.selectors';
import { Customer } from '@usf/customer-types';

export const selectSubmittedOrderViewModel = (orderId: string) =>
  createSelector(
    orderSelectors.selectOrderWithId(orderId),
    productWithAlternativesSelector,
    selectAppStateSelectedCustomer,
    selectAppStateUser,
    orderSelectors.selectOrderContextState,
    selectAllFeaturesEnabled([FEATURES.split_global_national_customers]),
    selectAllFeaturesEnabled([FEATURES.split_global_modify_vs_order]),
    selectOrderStatusDisplayEnabled(),
    selectUserProfiles,
    partnerSelectors.selectPartner,
    selectUserCustomizations,
    getCustomers,
    (
      order: Order,
      productsMap: Map<number, Product>,
      selectedCustomer: SelectedCustomerState,
      loggedInUser: UserState,
      orderState: OrderState,
      restrictedNationalFlagEnabled: boolean,
      modifyVSOrdersEnabled: boolean,
      orderStatusDisplayEnabled: boolean,
      userProfile: UserProfile[],
      partnerState: PartnerState,
      siteCustomizations: SiteCustomizationProfile,
      customers: Customer[],
    ) => {
      const customerReady =
        selectedCustomer?.customerNumber === order?.orderHeader?.customerNumber;

      const listWmtOrderNumber = OrderHeaderService.getListOfWMTOrders(
        orderState,
      );

      const orderHeader = order?.orderHeader;
      const orderItems = order?.orderItems;
      const tallies: SubmittedOrderTallies = {
        totalItems:
          (orderHeader?.totalUnits || 0) + (orderHeader?.totalEaches || 0),
        totalCases: orderHeader?.totalUnits || 0,
        totalEaches: orderHeader?.totalEaches || 0,
        totalPrice:
          (orderHeader?.totalDollars || 0) +
            (orderHeader?.totalSalesTax || 0) +
            (orderHeader?.totalCharges || 0) -
            Math.abs(orderHeader?.totalAllowances || 0) || 0,
        totalCasesOrdered: orderItems?.reduce(
          (accumulator, orderItem) =>
            accumulator + (orderItem?.unitsOrdered?.currentValue || 0),
          0,
        ),
        totalCasesReserved: orderItems?.reduce(
          (accumulator, orderItem) =>
            accumulator + (orderItem?.unitsReserved || 0),
          0,
        ),
        totalEachesOrdered: orderItems?.reduce(
          (accumulator, orderItem) =>
            accumulator + (orderItem?.eachesOrdered?.currentValue || 0),
          0,
        ),
        totalEachesReserved: orderItems?.reduce(
          (accumulator, orderItem) =>
            accumulator + (orderItem?.eachesReserved || 0),
          0,
        ),
      };

      let orderById = {
        orderHeader,
        orderItems,
      };

      const autoSubItems = new Map<number, SubbedItem>(
        orderItems
          ?.filter(
            item =>
              item.substituteFlag === 'A' && selectedCustomer?.autoSubProducts,
          )
          .map(item => {
            const orderItem = orderItems.find(
              subbed => item.substituteFor === subbed.productNumber,
            );
            const productSummary = productsMap.get(orderItem?.productNumber)
              ?.summary;

            return [
              item.substituteFor,
              { orderItem, productSummary } as SubbedItem,
            ];
          }),
      );

      if (!!orderItems) {
        orderById.orderItems = filterOutProductWithNoQuantity(
          orderItems,
          productsMap,
          autoSubItems,
        );
      }
      let orderStatusDetails = OrderStatusDetails[orderHeader?.orderStatus];

      if (
        orderStatusDisplayEnabled &&
        OrderHeaderService.orderIsPreparingToShip(orderHeader)
      ) {
        orderStatusDetails = OrderStatusDetails.PREPARING_TO_SHIP;
      }

      if (
        orderHeader?.orderType === 'VS' &&
        (orderStatusDetails === OrderStatusDetails.INVOICE ||
          !!orderHeader?.orderTracking?.length ||
          orderHeader?.trackingAvailable)
      ) {
        orderStatusDetails = OrderStatusDetails.SHIPPED;
      }

      if (
        (order?.orderHeader?.orderDelivered ?? false) ||
        (orderHeader?.deliveredDtm &&
          listWmtOrderNumber.has(order?.orderHeader?.tandemOrderNumber))
      ) {
        orderStatusDetails = OrderStatusDetails.DELIVERED;
      }

      if (
        partnerState?.partnerId &&
        !orderHeader?.purchaseOrderNumber &&
        (orderHeader?.orderStatus == OrderStatus.SUBMITTED ||
          orderHeader?.orderStatus == OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
          orderHeader?.orderStatus == OrderStatus.SUBMITTED_CREDIT_HOLD)
      ) {
        orderById = {
          ...orderById,
          orderHeader: {
            ...orderById.orderHeader,
            purchaseOrderNumber: PUNCHOUT_PO_PENDING,
          },
        };
      }

      if (
        orderStatusDisplayEnabled &&
        orderHeader?.displayOrderStatus &&
        orderHeader?.orderType === 'VS'
      ) {
        // New business rules for additional statuses
        switch (orderHeader.displayOrderStatus) {
          case OrderStatus.QUANTITIES_UPDATED:
            orderStatusDetails = OrderStatusDetails.QUANTITIES_UPDATED;
            break;
          case OrderStatus.OUT_FOR_DELIVERY:
            orderStatusDetails = OrderStatusDetails.OUT_FOR_DELIVERY;
            break;
          case OrderStatus.DELIVERY_DELAYED:
            orderStatusDetails = OrderStatusDetails.DELIVERY_DELAYED;
            break;
          case OrderStatus.ATTEMPTED_DELIVERY:
            orderStatusDetails = OrderStatusDetails.ATTEMPTED_DELIVERY;
            break;
          case OrderStatus.ORDER_CANCELLED:
            orderStatusDetails = OrderStatusDetails.ORDER_CANCELLED;
            break;
          case OrderStatus.DELIVERY_ISSUE:
            orderStatusDetails = OrderStatusDetails.DELIVERY_ISSUE;
            break;
          case OrderStatus.DELIVERED:
            orderStatusDetails = OrderStatusDetails.DELIVERED;
            break;
        }
      }

      let displayTitle = '';
      if (order) {
        displayTitle = OrderHeaderService.getDisplayTitle(
          orderStatusDetails,
          listWmtOrderNumber.has(order?.orderHeader?.tandemOrderNumber),
          orderHeader?.etaRangeMinimum,
          orderHeader?.etaRangeMaximum,
          (orderHeader?.orderDelivered ?? false) && !!orderHeader?.deliveredDtm,
          order,
          siteCustomizations,
          partnerState,
        );
      }

      const shipToCustomer =
        order?.orderHeader?.shipToCustomer !==
          order?.orderHeader?.customerNumber &&
        customers?.find(
          cust => cust?.customerNumber === order?.orderHeader?.shipToCustomer,
        );

      const shipToDepartmentName =
        order?.orderHeader?.shipToDepartment &&
        shipToCustomer?.departments?.find(
          dept =>
            Number(dept?.departmentNumber) ===
            order?.orderHeader?.shipToDepartment,
        )?.departmentName;

      let restrictToOG = false;

      if (restrictedNationalFlagEnabled) {
        restrictToOG =
          selectedCustomer?.restrictToOG === 'Y' ||
          selectedCustomer?.restrictToOG === 'M' ||
          selectedCustomer?.customerType === 'NA';
      }
      const accessRolePair = userProfile?.find(
        pair => pair.key === AccessRoleKeyEnum.ACCESS_ROLE_KEYS,
      );

      let noOrderEnabled = accessRolePair
        ? accessRolePair.value === AccessRoleValueEnum.NO_SUBMIT_VALUE
        : false;
      const submittedOrderVM: SubmittedOrderViewModel = {
        submittedOrderById: orderById,
        tallies,
        displayTitle,
        isEditable:
          OrderHeaderService.isOrderEditable(
            orderHeader,
            modifyVSOrdersEnabled,
          ) && !restrictToOG,
        isCancellable:
          OrderHeaderService.isOrderCancellable(
            orderHeader,
            modifyVSOrdersEnabled,
          ) &&
          !restrictToOG &&
          !noOrderEnabled,
        products: productsMap,
        autoSubItems,
        customerReady,
        tmUser: loggedInUser?.tmUser === 'Y',
        restrictToOG,
        willCallInFlight: orderState?.willCallInFlight,
        showTransferButton:
          partnerState?.punchoutProfile &&
          !partnerState.punchoutProfile.autoTransfer &&
          partnerState.punchoutProfile.retransferLinkFlag,
        transferButtonText: partnerState?.punchoutProfile?.transferButton,
        modifyButtonText: partnerState?.punchoutProfile?.updateButton,
        orderStatusDisplayEnabled,
        orderStatusDetails,
        customersWithTheSameDivision: customers?.filter(
          customer => customer.divisionNumber === orderHeader?.divisionNumber,
        ).length,
        shipToCustomer,
        shipToDepartmentName,
      };
      return submittedOrderVM;
    },
  );

export const filterOutProductWithNoQuantity = (
  orderItems: any,
  productsMap: Map<number, Product>,
  autoSubItems: Map<number, SubbedItem>,
) => {
  if (!orderItems || orderItems.length === 0) {
    return orderItems;
  }
  return orderItems.filter(item => {
    if (autoSubItems.has(item.productNumber)) {
      return false;
    }
    if (
      item.eachesReserved > 0 ||
      item.unitsReserved > 0 ||
      item.eachesOrdered?.currentValue > 0 ||
      item.unitsOrdered?.currentValue > 0
    ) {
      return true;
    } else {
      productsMap.forEach((v, k) => {
        if (k === item.productNumber) {
          productsMap.delete(k);
        }
      });
    }
  });
};

export const getOrderTrackingGroups = (orderId: string) =>
  createSelector(orderSelectors.selectOrderWithId(orderId), (order: Order) => {
    const orderTracking = [];
    order?.orderHeader?.orderTracking?.forEach(val =>
      orderTracking.push(Object.assign({}, val)),
    );
    const multipleTrackingNumberProductNumbers = orderTracking?.reduce(
      (a, e) => {
        a[e.productNumber] = ++a[e.productNumber] || 0;
        return a;
      },
      {},
    );
    let filteredOrderItems = order?.orderItems?.filter(item => {
      return (
        item.eachesReserved > 0 ||
        item.unitsReserved > 0 ||
        item.eachesOrdered?.currentValue > 0 ||
        item.unitsOrdered?.currentValue > 0
      );
    });
    const multipleTrackingNumberProducts = orderTracking.filter(
      e => multipleTrackingNumberProductNumbers[e.productNumber],
    );
    multipleTrackingNumberProducts.map(prod => {
      const partialDelivery = multipleTrackingNumberProducts.filter(
        p =>
          p.productNumber === prod.productNumber &&
          p.trackingNumber !== prod.trackingNumber,
      );
      partialDelivery.forEach(pd => {
        prod.trackingNumber += '|' + pd.trackingNumber.split('|')[0];
      });
    });
    orderTracking.map(
      obj =>
        multipleTrackingNumberProducts?.find(
          o =>
            o.productNumber === obj.productNumber &&
            o.trackingNumber === obj.trackingNumber.split('|')[0],
        ) || obj,
    );
    let groups: OrderTrackingGroup = orderTracking?.reduce(
      (
        orderTrackingGroups: OrderTrackingGroup,
        orderTracking: OrderTrackingItem,
      ) => {
        orderTrackingGroups[orderTracking.trackingNumber.split('|')[0]] =
          orderTrackingGroups[orderTracking.trackingNumber.split('|')[0]] || [];

        orderTrackingGroups[orderTracking.trackingNumber.split('|')[0]].push({
          orderTracking,
          orderItem: filteredOrderItems?.find(
            item => item?.productNumber === orderTracking?.productNumber,
          ),
        });
        return orderTrackingGroups;
      },
      Object.create(null),
    );
    //multiple item with career tracking and some without career tracking
    let untrackedItems;
    if (
      order?.orderHeader?.orderTracking?.length !== 0 &&
      order?.orderHeader?.orderTracking?.length < filteredOrderItems?.length
    ) {
      const orderTrackingProductNumbers: number[] = order?.orderHeader?.orderTracking.map(
        ot => ot.productNumber,
      );
      untrackedItems = filteredOrderItems
        ?.filter(oi => !orderTrackingProductNumbers.includes(oi.productNumber))
        .map(orderItem => {
          return { orderItem };
        });
    }
    if (
      Object.keys(groups).length > 0 &&
      order?.orderHeader?.orderTracking?.length < filteredOrderItems?.length
    ) {
      groups[''] = untrackedItems;
    } else if (Object.keys(groups).length === 0) {
      groups = {
        '': filteredOrderItems.map(orderItem => {
          return { orderItem };
        }),
      };
    }
    return groups;
  });
