export const PATHS = {
  // main navigation
  HOME: 'home',
  B2C: 'b2c',
  LOGIN: 'login',
  LOGIN_PUNCHOUT: 'login/punchout',
  LOGIN_PUNCHTHRU: 'login/punchthru',
  LOGIN_ERROR: 'login-error',
  LOGOUT: 'logout',
  PUNCHOUT: 'punchout',
  DESKTOP: 'desktop',
  TOUCH: 'touch',
  GUEST_LOGIN: 'guest-login',

  // better buys
  BETTER_BUYS: 'better-buys',

  // deals
  DEALS: 'deals',
  ALL_DEALS: 'all-available-deals',

  // did you forget
  DYF: 'did-you-forget',

  // inventories
  INVENTORY: 'inventory',
  ALL_INVENTORY: 'all-inventory',
  CREATE_INVENTORY: 'create-inventory',
  INVENTORY_WORKSHEET: 'inventory-worksheet/:inventoryId',
  MANAGE_NON_USF_PRODUCTS: 'manage-non-usf-products',
  MANAGE_VENDORS: 'manageVendors',
  FOOD_COST: 'food-cost-calculator',

  // lists
  LISTS: 'lists',
  LIST_DETAIL: 'lists/detail/:listId',
  LIST_MANAGEMENT: 'lists/management/:listId',
  LIST_RECENTLY_PURCHASED: 'lists/recentlyPurchased',
  MASTER_LIST: 'lists/masterList/:listId',
  LISTS_SEARCH_PRODUCT_NUMBERS: 'lists/searchProductNumbers',

  // maintenance
  SCHEDULED_MAINTENANCE: 'scheduled-maintenance',

  // more
  MORE: 'more',
  PRODUCTS_DEALS_MENU: 'more/menu-pages/products-deals-menu',
  MY_BUSINESS_MENU: 'more/menu-pages/my-business-menu',
  MY_ORDERS_MENU: 'more/menu-pages/my-orders-menu',

  // notifications
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_DETAILS: 'notification-details/:notificationId',
  NOTE_DETAILS: 'note-details/:noteId',
  TM_NOTES: '?tmnotes=true',

  // orders
  ORDER: 'order',
  ORDER_CONFIRMATION: 'order/order-confirmation',
  MY_ORDERS: 'my-orders',
  MY_ORDERS_VIEW: 'my-orders-view',
  IMPORT_ORDER: 'order/import-order-validation',
  IMPORTED_ORDERS: 'order/imported-orders',
  ORDER_SUBMITTED: 'order/submitted-order/',
  QUICK_ORDER_ENTRY: 'order/quick-order-entry',

  // payments
  PAYMENTS: 'payments',
  PAYMENTS_ALL_INVOICES: 'payments/all-invoices',
  PAYMENTS_VIEW_INVOICE: 'payments/view-invoice',
  PAYMENTS_MAKE_PAYMENT: 'payments/make-payment',
  PAYMENTS_PAYMENT_ACTIVITY: 'payments/payment-activity',
  PAYMENTS_PAYMENT_PREFERENCES: 'payments/payment-preferences',
  PAYMENTS_ADJUST_PAYMENT: 'payments/make-payment/adjust-payment',
  PAYMENTS_REVIEW_PAYMENT: 'payments/make-payment/review-payment',
  PAYMENTS_PAYMENT_CONFIRMATION: 'payments/make-payment/payment-confirmation',

  // products
  PRODUCT_DETAIL: 'products/:productId',
  REVIEW_PRODUCTS: 'review-products',

  // profile
  PROFILE: 'profile',

  // request credit
  REQUEST_CREDIT: 'request-credit',
  REQUEST_CREDIT_CONFIRMATION: 'request-credit/confirmation',

  //list updates
  LIST_UPDATES: 'list-updates',

  // scoop
  SCOOP: 'scoop',

  // search
  SEARCH: 'search',
  NEW_SEARCH: 'new-search',
  NEW_SEARCH_SUBSTITUTES: 'new-search/substitutes',
  SEARCH_CATALOG: 'search/catalog',
  SEARCH_COMPARE: 'search/compare',
  SEARCH_BROWSE: 'search/browse',
  SEARCH_BROWSE_CATEGORIES: 'search/browse/categories',
  // support center
  SUPPORT_CENTER: 'support-center',

  // error
  NOT_FOUND: '404',

  // not implemented
  DELIVERIES: 'deliveries',

  // error page
  ERROR: 'error',
};
