import { Injectable } from '@angular/core';
import { State } from 'src/app/store';
import { Store } from '@ngrx/store';
import { hasListUpdates } from '@usf/ngrx-list';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListUpdatesService {
  constructor(private store: Store<State>) {}

  listUpdatesExist$ = (): Observable<boolean> =>
    this.store.select(hasListUpdates);
}
